/**
 * Twinn API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 
 import { Observable }                                        from 'rxjs';
import { Configuration } from 'src/app/helpers/configuration';
import { CheckUserResponse } from 'src/app/api/model/model/checkUserResponse';
import { AuthLoginBody, ForLoginBody } from 'src/app/api/model/model/authLoginBody';
import { VcardUsersBody } from 'src/app/api/model/model/vcardUsersBody';
import { InlineResponse200 } from 'src/app/api/model/model/inlineResponse200';
import { VcardGetUserBody } from 'src/app/api/model/model/vcardGetUserBody';
import { InlineResponse2001 } from 'src/app/api/model/model/inlineResponse2001';
import { VcardCreateUserBody } from 'src/app/api/model/model/vcardCreateUserBody';
import { InlineResponse2002 } from 'src/app/api/model/model/inlineResponse2002';
import { VcardGetAllVirtualCardBody } from 'src/app/api/model/model/vcardGetAllVirtualCardBody';
import { InlineResponse2005 } from 'src/app/api/model/model/inlineResponse2005';
import { InlineResponse2006 } from 'src/app/api/model/model/inlineResponse2006';
import { VcardGetCardTransactionsBody } from 'src/app/api/model/model/vcardGetCardTransactionsBody';
import { VcardGetVirtualCardDetailsBody } from 'src/app/api/model/model/vcardGetVirtualCardDetailsBody';
import { updateAccountBody } from 'src/app/api/model/model/updateAccount';
 
 
 
 
 @Injectable({
     providedIn: 'root'
   })
   
 export class AuthService {
  authenticated = true;

   
     protected basePath = "https://api.accountpe.com/api/sandbox" ;
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
     isBrowser!: boolean;
     access_token!: string | null;
 
 
     constructor(protected httpClient: HttpClient) {
        
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * Check User Exist
      * 
      * @param username 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public checkUserExist(username: string, observe?: 'body', reportProgress?: boolean): Observable<CheckUserResponse>;
     public checkUserExist(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CheckUserResponse>>;
     public checkUserExist(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CheckUserResponse>>;
     public checkUserExist(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (username === null || username === undefined) {
             throw new Error('Required parameter username was null or undefined when calling checkUserExist.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<CheckUserResponse>('get',`${this.basePath}/api/v1/auth/checkUser/${encodeURIComponent(String(username))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
       public apiVcardAuthLoginPost(body?: AuthLoginBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
       public apiVcardAuthLoginPost(body?: AuthLoginBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
       public apiVcardAuthLoginPost(body?: AuthLoginBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
       public apiVcardAuthLoginPost(body?: AuthLoginBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
   
   
           let headers = this.defaultHeaders;
   
           // authentication (AuthToken) required
           if (this.configuration.accessToken) {
               const accessToken = typeof this.configuration.accessToken === 'function'
                   ? this.configuration.accessToken()
                   : this.configuration.accessToken;
               headers = headers.set('Authorization', 'Bearer ' + accessToken);
           }
           // to determine the Accept header
           let httpHeaderAccepts: string[] = [
           ];
           const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
           if (httpHeaderAcceptSelected != undefined) {
               headers = headers.set('Accept', httpHeaderAcceptSelected);
           }
   
           // to determine the Content-Type header
           const consumes: string[] = [
               'application/json',
               'application/xml'
           ];
           const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
           if (httpContentTypeSelected != undefined) {
               headers = headers.set('Content-Type', httpContentTypeSelected);
           }
   
           return this.httpClient.request<any>('post',`${this.basePath}/admin/login`,
               {
                   body: body,
                   withCredentials: this.configuration.withCredentials,
                   headers: headers,
                   observe: observe,
                   reportProgress: reportProgress
               }
           );
       }
       /**
      * Get all user list
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiVcardUsersPost(body?: VcardUsersBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
     public apiVcardUsersPost(body?: VcardUsersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
     public apiVcardUsersPost(body?: VcardUsersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
     public apiVcardUsersPost(body?: VcardUsersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
         this.access_token=localStorage.getItem('access_token')
        
         // const access_token =localStorage.getItem('access_token')
 
         // authentication (AuthToken) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + this.access_token);
         }
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/users`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
     
     /**
      * Get user details with user_id
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiVcardGetUserPost(body?: VcardGetUserBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
     public apiVcardGetUserPost(body?: VcardGetUserBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
     public apiVcardGetUserPost(body?: VcardGetUserBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
     public apiVcardGetUserPost(body?: VcardGetUserBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
         this.access_token=localStorage.getItem('access_token')
         if(this.isBrowser) {
          this.access_token=localStorage.getItem('access_token')
           }
 
         // authentication (AuthToken) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + this.access_token);
         }
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<InlineResponse2001>('post',`${this.basePath}/get_user`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
     /**
      * Create User with given params
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiVcardCreateUserPost(body?: VcardCreateUserBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
     public apiVcardCreateUserPost(body?: VcardCreateUserBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
     public apiVcardCreateUserPost(body?: VcardCreateUserBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
     public apiVcardCreateUserPost(body?: VcardCreateUserBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
         this.access_token=localStorage.getItem('access_token')
 
         // authentication (AuthToken) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + this.access_token);
         }
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<InlineResponse2002>('post',`${this.basePath}/create_user`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     
     
     /**
      * Get All  Virtual Card
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiVcardGetAllVirtualCardPost(body?: VcardGetAllVirtualCardBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
     public apiVcardGetAllVirtualCardPost(body?: VcardGetAllVirtualCardBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
     public apiVcardGetAllVirtualCardPost(body?: VcardGetAllVirtualCardBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
     public apiVcardGetAllVirtualCardPost(body?: VcardGetAllVirtualCardBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
         this.access_token=localStorage.getItem('access_token')
 
         // authentication (AuthToken) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + this.access_token);
         }
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<InlineResponse2005>('post',`${this.basePath}/get_all_virtual_card`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

       public apiForgotLoginPost(body?: ForLoginBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
       public apiForgotLoginPost(body?: ForLoginBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
       public apiForgotLoginPost(body?: ForLoginBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
       public apiForgotLoginPost(body?: ForLoginBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
   
   
           let headers = this.defaultHeaders;
   
           // authentication (AuthToken) required
           if (this.configuration.accessToken) {
               const accessToken = typeof this.configuration.accessToken === 'function'
                   ? this.configuration.accessToken()
                   : this.configuration.accessToken;
               headers = headers.set('Authorization', 'Bearer ' + accessToken);
           }
           // to determine the Accept header
           let httpHeaderAccepts: string[] = [
           ];
           const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
           if (httpHeaderAcceptSelected != undefined) {
               headers = headers.set('Accept', httpHeaderAcceptSelected);
           }
   
           // to determine the Content-Type header
           const consumes: string[] = [
               'application/json',
               'application/xml'
           ];
           const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
           if (httpContentTypeSelected != undefined) {
               headers = headers.set('Content-Type', httpContentTypeSelected);
           }
   
           return this.httpClient.request<any>('post',`https://api.accountpe.com/api/vcard/forgot_password`,
               {
                   body: body,
                   withCredentials: this.configuration.withCredentials,
                   headers: headers,
                   observe: observe,
                   reportProgress: reportProgress
               }
           );
       }

       public apiChangePassPost(body?: ForLoginBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
       public apiChangePassPost(body?: ForLoginBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
       public apiChangePassPost(body?: ForLoginBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
       public apiChangePassPost(body?: ForLoginBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
   
   
           let headers = this.defaultHeaders;
   
           // authentication (AuthToken) required
           if (this.configuration.accessToken) {
               const accessToken = typeof this.configuration.accessToken === 'function'
                   ? this.configuration.accessToken()
                   : this.configuration.accessToken;
               headers = headers.set('Authorization', 'Bearer ' + accessToken);
           }
           // to determine the Accept header
           let httpHeaderAccepts: string[] = [
           ];
           const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
           if (httpHeaderAcceptSelected != undefined) {
               headers = headers.set('Accept', httpHeaderAcceptSelected);
           }
   
           // to determine the Content-Type header
           const consumes: string[] = [
               'application/json',
               'application/xml'
           ];
           const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
           if (httpContentTypeSelected != undefined) {
               headers = headers.set('Content-Type', httpContentTypeSelected);
           }
   
           return this.httpClient.request<any>('post',`https://api.accountpe.com/api/vcard/update_password`,
               {
                   body: body,
                   withCredentials: this.configuration.withCredentials,
                   headers: headers,
                   observe: observe,
                   reportProgress: reportProgress
               }
           );
       }

         
     /**
      * Get All  Virtual Card
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiVcardGetSummary(body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiVcardGetSummary(body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiVcardGetSummary(body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiVcardGetSummary(body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
         this.access_token=localStorage.getItem('access_token')
 
         // authentication (AuthToken) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + this.access_token);
         }
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<InlineResponse2005>('post',`${this.basePath}/summary`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
        }

        //  wallet
                 
     /**
      * Get All  Virtual Card
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiVcardGetwallet(body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiVcardGetwallet(body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiVcardGetwallet(body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiVcardGetwallet(body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
         this.access_token=localStorage.getItem('access_token')
 
         // authentication (AuthToken) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + this.access_token);
         }
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'application/xml'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<InlineResponse2005>('post',`${this.basePath}/get_wallet_transaction`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

          /**
      * Get All  Virtual Card
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
          public apiVcardGetadmincard(body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
          public apiVcardGetadmincard(body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
          public apiVcardGetadmincard(body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
          public apiVcardGetadmincard(body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      
      
              let headers = this.defaultHeaders;
              this.access_token=localStorage.getItem('access_token')
      
              // authentication (AuthToken) required
              if (this.configuration.accessToken) {
                  const accessToken = typeof this.configuration.accessToken === 'function'
                      ? this.configuration.accessToken()
                      : this.configuration.accessToken;
                  headers = headers.set('Authorization', 'Bearer ' + this.access_token);
              }
              // to determine the Accept header
              let httpHeaderAccepts: string[] = [
                  'application/json',
                  'application/xml'
              ];
              const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
              if (httpHeaderAcceptSelected != undefined) {
                  headers = headers.set('Accept', httpHeaderAcceptSelected);
              }
      
              // to determine the Content-Type header
              const consumes: string[] = [
                  'application/json',
                  'application/xml'
              ];
              const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
              if (httpContentTypeSelected != undefined) {
                  headers = headers.set('Content-Type', httpContentTypeSelected);
              }
      
              return this.httpClient.request<InlineResponse2005>('post',`${this.basePath}/get_all_vcard_admin`,
                  {
                      body: body,
                      withCredentials: this.configuration.withCredentials,
                      headers: headers,
                      observe: observe,
                      reportProgress: reportProgress
                  }
              );
          }

        /**
     * Get vCard Transactions
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVcardGetCardTransactionsPost(body?: VcardGetCardTransactionsBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVcardGetCardTransactionsPost(body?: VcardGetCardTransactionsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVcardGetCardTransactionsPost(body?: VcardGetCardTransactionsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVcardGetCardTransactionsPost(body?: VcardGetCardTransactionsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (AuthToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse2006>('post',`${this.basePath}/get_card_transactions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        
    }
    /**
     * Get Virtual Card Details
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVcardGetVirtualCardDetailsPost(body?: VcardGetVirtualCardDetailsBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
    public apiVcardGetVirtualCardDetailsPost(body?: VcardGetVirtualCardDetailsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
    public apiVcardGetVirtualCardDetailsPost(body?: VcardGetVirtualCardDetailsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
    public apiVcardGetVirtualCardDetailsPost(body?: VcardGetVirtualCardDetailsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (AuthToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse2005>('post',`${this.basePath}/get_virtual_card_details`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

   
        public apiGetadmindetails(body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
        public apiGetadmindetails(body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
        public apiGetadmindetails(body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
        public apiGetadmindetails(body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
    
            let headers = this.defaultHeaders;
            this.access_token=localStorage.getItem('access_token')
    
            // authentication (AuthToken) required
            if (this.configuration.accessToken) {
                const accessToken = typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken()
                    : this.configuration.accessToken;
                headers = headers.set('Authorization', 'Bearer ' + this.access_token);
            }
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json',
                'application/xml'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json',
                'application/xml'
            ];
            const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
            if (httpContentTypeSelected != undefined) {
                headers = headers.set('Content-Type', httpContentTypeSelected);
            }
    
            return this.httpClient.request<InlineResponse2005>('post',`${this.basePath}/profile_data`,
                {
                    body: body,
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }

    
        /**
     * Get vCard Transactions
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public apiUpdateSetting(body?: updateAccountBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
        public apiUpdateSetting(body?: updateAccountBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
        public apiUpdateSetting(body?: updateAccountBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
        public apiUpdateSetting(body?: updateAccountBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
    
            let headers = this.defaultHeaders;
    
            // authentication (AuthToken) required
            if (this.configuration.accessToken) {
                const accessToken = typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken()
                    : this.configuration.accessToken;
                headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
            }
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json',
                'application/xml'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json',
                'application/xml'
            ];
            const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
            if (httpContentTypeSelected != undefined) {
                headers = headers.set('Content-Type', httpContentTypeSelected);
            }
    
            return this.httpClient.request<InlineResponse2006>('post',`${this.basePath}/update_profile_data`,
                {
                    body: body,
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
    
            
        }

     
 
 
 

     
 
 
    
 }
 