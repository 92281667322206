import { environment } from "../../environments/environment.prod";

export interface ConfigurationParameters {
  apiKeys?: { [key: string]: string };
  username?: string;
  password?: string;
  accessToken?: string | (() => string);
  basePath?: string;
  withCredentials?: boolean;
}
const baseauthurl=  "https://dev-crm-api.tooliqa.com/api/auth"
const basecrmurl =  "https://dev-crm-api.tooliqa.com/api/crm"

// const baseauthurl = environment.auth_api_url;
// const basecrmurl = environment.crm_api_url;

// const baseauthurl = 'https://dev-auth-api.twinnpro.com';
// const basecrmurl = 'https://dev-crm-api.tooliqa.com/api/crm';

export class AuthUrl {
  authapiurl = baseauthurl + '/api/v1/auth/check_user/';
  loginapiurl = baseauthurl + '/api/vcard/admin/login';
  signupUrl = baseauthurl + '/api/v1/auth/signup';
  countryurl = baseauthurl + '/api/v1/auth/country';
  checkuserexist = baseauthurl + '/api/v1/auth/checkUser/';
  updatepassurl = baseauthurl + '/api/v1/auth/updatePassword';
  setpassurl=baseauthurl+'/api/v1/auth/reset/Password';
  sendotpurl = baseauthurl + '/api/v1/auth/app/forgot_password?username=';
  checkuser = baseauthurl + '/api/v1/user/me';
  loginuser = baseauthurl + '/api/v1/user/'
  updateprofile = baseauthurl + '/api/v1/auth';
}

export class ProfileUrl{
  userprofile = baseauthurl + '/api/v1/user/me';
  updateprofile=baseauthurl + '/api/v1/auth';
  updatepassword=baseauthurl+'/api/v1/auth/updatePassword';
  userlisturl=baseauthurl+'/api/v1/user/list';
}

export class  AccountUrl{
  accountdetailsurl = basecrmurl + '/api/v1/account';
  accountaddresssurls = basecrmurl+'/account/address';
  addresslisturl=basecrmurl+'/api/v1/account/address';
  deleteaddress=basecrmurl+'/api/v1/account/address/';
  addadddressurl=basecrmurl+'/api/v1/account/address';
  updateaddressurl=basecrmurl+'/api/v1/account/address';
}

export class ProjectUrl {
  projectsummaryurl = basecrmurl + '/api/v1/project/summary/list';
  projectactivityurl = basecrmurl + '/api/v1/project/activity/list';
  projectlisturl = basecrmurl + '/api/v1/project';
  addprojecturl = basecrmurl + '/api/v1/project';
  duplicateProjecturl = basecrmurl + '/api/v1/project/duplicate/project';
  userlisturl = baseauthurl + '/api/v1/user/list';
  projectteamlist = basecrmurl + '/api/v1/team/';
  updateflag = basecrmurl + '/api/v1/project/flag';
  updatearchive = basecrmurl + '/api/v1/project/archieve/status';
  nonmemberurl = basecrmurl + '/api/v1/team/nonmember/';
  memberreq = basecrmurl + '/api/v1/team';
  getmemberreq = basecrmurl + '/api/v1/team/';
  createmoodboarur = basecrmurl + '/api/v1/project/';
  getMoodBoard = basecrmurl + '/api/v1/project/';
  deleteMoodBoard = basecrmurl + '/api/v1/project/moodboard/';
  getTwinnStudioImages = basecrmurl + '/api/v1/project/';
  generateSpace = basecrmurl + '/api/v1/project/';
  deleteShortlistImage = basecrmurl + '/api/v1/project/';
  addShortListedImages = basecrmurl + '/api/v1/project/';
  getTwinnStudioList = basecrmurl + '/api/v1/project/';
  remaginSpace = basecrmurl + '/api/v1/project/';
  imageAddToMoodBoard = basecrmurl + '/api/v1/project/';
  exportproject=basecrmurl+'/api/v1/unity/';
  appscandata=basecrmurl+'/api/v1/app-scan/';
}

export class TeamUrl {
  teamlisturl = basecrmurl + '/api/v1/team';
  userdetailurl = baseauthurl + '/api/v1/user/';
  userprojectlisturl = basecrmurl + '/api/v1/project/';
  usertaskurl = basecrmurl + '/api/v1/task/';
  adduser = basecrmurl + '/api/v1/team/add/user';
  deleteuser = basecrmurl + '/api/v1/team/user/';
}

export class AssetUrl {
  libraryurl = basecrmurl + '/api/v1/library/library';
  asseturl = basecrmurl + '/api/v1/library/assets/list/data';
  assetdetailsurl = basecrmurl + '/api/v1/library/assets/';
  tagslisturl=basecrmurl+'/api/v1/library/tags';
  assetbysubcategoryid = basecrmurl+"/api/v1/library/assets/"
  addassets = basecrmurl + '/api/v1/library/';
  getcategorylist = basecrmurl + '/api/v1/library/assets/category/list/data';
  getcategory = basecrmurl + '/api/v1/library/assets/category/list';
  getassetfile = basecrmurl + '/api/v1/library/assets/';
  updateLikeurl=basecrmurl+'/api/v1/library/like';
  filterassets=basecrmurl+'/api/v1/library/assets/filter';
  
}

export class MoodboardUrl {
  getmblistrl = basecrmurl + '/api/v1/moodboard';
  gettemplistrl = basecrmurl + '/api/v1/moodboard/moodboard/template';
  deletemb=basecrmurl+'/api/v1/moodboard/';
  renamemb=basecrmurl+'/api/v1/moodboard/';
  updatemburl=basecrmurl+'/api/v1/moodboard/';
  gettwinnstudioimage=basecrmurl+'"/api/v1/moodboard/twinn-studio/generate-space';
  assignmdtoprojecturl=basecrmurl+'/api/v1/moodboard/';
  getcanvassetting=basecrmurl+'/api/v1/canvas/';
  
}

export class ClientUrl {
  clientlisturl = basecrmurl + '/api/v1/client';
  clientbyidurl = basecrmurl + '/api/v1/client/';
  projectbyclientidurl =
    basecrmurl + '/api/v1/client/{clientId}/projects?client_id=';
  clientcontactlisturl = basecrmurl + '/api/v1/client/';
  clientcontactdelete = basecrmurl + '/api/v1/client/contact/';
  clientcontactupdate = basecrmurl + '/api/v1/client/contact';
  addclientcontact = basecrmurl + '/api/v1/client/contact';
}


export class vendorUrl {
  vendorlisturl = basecrmurl + '/api/v1/vendor';
  vendorbyidurl = basecrmurl + '/api/v1/vendor/';
  projectbyvendoridurl =
    basecrmurl + '/api/v1/vendor/{vendorId}/projects?vendor_id=';
  vendorcontactlisturl = basecrmurl + '/api/v1/vendor/';
  vendorcontactdelete = basecrmurl + '/api/v1/vendor/contact/';
  vendorcontactupdate = basecrmurl + '/api/v1/vendor/contact';
  addvendorcontact = basecrmurl + '/api/v1/vendor/contact';
}

export class StorageUrl {
  getfileurl = basecrmurl + '/api/v1/storage';
  getstorageurl = basecrmurl + '/api/v1/storage?path=';
  deletefileurl = basecrmurl + '/api/v1/storage?file_id=';
  addfolderurl = basecrmurl + '/api/v1/storage/folder';
  deletefolderurl = basecrmurl + '/api/v1/storage/folder?folder_name=';
  renamefolderurl = basecrmurl + '/api/v1/storage/folder/rename';
  movefolderurl = basecrmurl + '/api/v1/storage/folder/move';
  copyfolderurl = basecrmurl + '/api/v1/storage/folder/copy';
}

export class DriveUrl {
  containerurl = 'https://cdn.twinnpro.com';
  sastoken =
    '?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2025-04-03T01:59:25Z&st=2022-04-02T17:59:25Z&spr=https,http&sig=tGbaS8gpKHNWTDuNOub2TKX6pFf1bXqgdpGzbSUVSjo%3D';
}

export class TaskUrl {
  todotaskurl = basecrmurl + '/api/v1/task/todo';
  addtodourl = basecrmurl + '/api/v1/task/todo';
  updateflag = basecrmurl + '/api/v1/task/todo/flag';
  updatetodostatus = basecrmurl + '/api/v1/task/todo/status';
  updatask = basecrmurl + '/api/v1/task/status';
  deletetodotask = basecrmurl + '/api/v1/tasktodo';
  mytaskurl = basecrmurl + '/api/v1/task/mytask';
  mytaskdateurl = basecrmurl + '/api/v1/task/mytask/date/list';
  assigntaskurl = basecrmurl + '/api/v1/task/assigntask';
  deletetask = basecrmurl + '/api/v1/task/';
  stageurl = basecrmurl + '/api/v1/task/project/stage';
  updatestageurl = basecrmurl + '/api/v1/task/stage/status';
  delstageurl = basecrmurl + '/api/v1/task/project/stage/';
  addtaskurl = basecrmurl + '/api/v1/task';
  updatetaskfileurl=basecrmurl+"/api/v1/task/files/tasks/update"
}

export class EstimateUrl {
  estimatelisturl = basecrmurl + '/api/v1/estimate/';
  addestimateurl = basecrmurl + '/api/v1/estimate';
  addtasktoestimate=basecrmurl+"/api/v1/task/add/estimate/task"
  estimatedeleteurl = basecrmurl + '/api/v1/estimate/';
  estimatedetailsurl = basecrmurl + '/api/v1/estimate/';
  addestimateitemurl = basecrmurl + '/api/v1/estimate/items';
  newestimateitem = basecrmurl + '/api/v1/estimate/estimate/items';
  estimatecategoryurl = basecrmurl + '/api/v1/estimate/items/category';
  estimatepreviewurl = basecrmurl + '/api/v1/estimate/';
  estimatePdfUrl = basecrmurl +'/api/v1/estimate/';
  commentlisturl= basecrmurl+"/api/v1/estimate/";
  addcommenturl= basecrmurl+"/api/v1/estimate/comment";
  approveitemurl= basecrmurl+"/api/v1/estimate/";
}

export class TwinnStudioUrl{
  getimagelist=basecrmurl+'/api/v1/project/twinn-studio/images';
  addshortlistedimage=basecrmurl+'/api/v1/project/twinn-studio/images/shortlist/add';
  generatespce=basecrmurl+'/api/v1/moodboard/twinn-studio/generate-space';
  deleteimageurl=basecrmurl+"/api/v1/project/twinn-studio/images/shortlist/remove";
}

export class ImageGeneratorUrl {
  generatefileurl = basecrmurl + '/api/v1/image_generator';
}

export class SharedServiceUrl {
  searchurl = basecrmurl + '/api/v1/search?query=';
}

export class Configuration {
  apiKeys?: { [key: string]: string };
  username?: string;
  password?: string;
  accessToken?: string | (() => string);
  basePath?: string;
  withCredentials?: boolean;

  constructor(configurationParameters: ConfigurationParameters = {}) {
    this.apiKeys = configurationParameters.apiKeys;
    this.username = configurationParameters.username;
    this.password = configurationParameters.password;
    this.accessToken =  'string';
    this.basePath = configurationParameters.basePath;
    this.withCredentials = configurationParameters.withCredentials;
  }

  /**
   * Select the correct content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param contentTypes - the array of content types that are available for selection
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  public selectHeaderContentType(contentTypes: string[]): string | undefined {
    if (contentTypes.length == 0) {
      return undefined;
    }

    let type = contentTypes.find((x) => this.isJsonMime(x));
    if (type === undefined) {
      return contentTypes[0];
    }
    return type;
  }

  /**
   * Select the correct accept content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param accepts - the array of content types that are available for selection.
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  public selectHeaderAccept(accepts: string[]): string | undefined {
    if (accepts.length == 0) {
      return undefined;
    }

    let type = accepts.find((x) => this.isJsonMime(x));
    if (type === undefined) {
      return accepts[0];
    }
    return type;
  }

  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  public isJsonMime(mime: string): boolean {
    const jsonMime: RegExp = new RegExp(
      '^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$',
      'i'
    );
    return (
      mime != null &&
      (jsonMime.test(mime) ||
        mime.toLowerCase() === 'application/json-patch+json')
    );
  }
}
